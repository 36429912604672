<template>
  <v-container fluid>
    <h3 class="page-title d-flex justify-space-between align-center">
      購買一番賞
    </h3>
    <v-row align="center">
      <v-col cols="6" sm="3">
        <v-select
          label="店點"
          hide-details
          v-model="branch"
          :items="branches"
          outlined
          dense
        ></v-select>
      </v-col>
      <v-col cols="6" sm="3">
        <v-text-field
          outlined
          dense
          hide-details
          label="關鍵字"
          v-model="keyword"
          clearable
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          class="row-pointer"
          :headers="headers"
          :items="filterData"
          :mobile-breakpoint="0"
          item-key="index"
          hide-default-footer
          :footer-props="{
            itemsPerPageText: '每頁幾筆',
            itemsPerPageOptions: [-1],
          }"
          @click:row="selectRow"
        >
          <!-- <template v-slot:item.plans="{ item }">
            <div v-for="plan of item.plans">
              {{ plan.count }}抽 - ${{ plan.amount }}
            </div>
          </template> -->
          <!-- <template v-slot:item.rewards="{ item }">
            <div v-for="reward of item.rewards">
              #{{ reward.number }} - {{ reward.reward }}
            </div>
          </template> -->
          <template v-slot:item.count="{ item }">
            {{ item.count - item.sold }}/{{ item.count }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <DialogTradeIchiban
      v-if="dialog.ichiban"
      v-model="dialog.ichiban"
      :item="selectedItem"
      @load="load"
    ></DialogTradeIchiban>
  </v-container>
</template>

<script>
import util from "@/mixins/util";
import dayjs from "dayjs";
import _ from "lodash";
import DialogTradeIchiban from "../../components/dashboard/ichiban/DialogTradeIchiban.vue";

export default {
  name: "TradeIchiban",
  components: { DialogTradeIchiban },
  mixins: [util],
  data: () => ({
    dialog: {
      ichiban: false,
    },
    branch: "",
    data: [],
    keyword: "",
    selectedItem: null,
    uploadImageItem: null,
    headers: [
      { text: "編號", value: "key", align: "left", sortable: false },
      { text: "抽數", value: "count", align: "center", sortable: true },
      { text: "一番賞名稱", value: "name", align: "left", sortable: false },
      // { text: "已抽", value: "sold", align: "center", sortable: true },
      // { text: "中獎", value: "win", align: "center", sortable: true },
      // { text: "價格", value: "plans", align: "left", sortable: true },
      // { text: "獎項", value: "rewards", align: "left", sortable: true },
    ],
  }),
  computed: {
    _: () => _,
    filterData() {
      let data = this.data;
      data = data.filter((item) => !item.isOnline);
      if (this.keyword) {
        data = data.filter(
          (item) =>
            item.name.includes(this.keyword) || item.key.includes(this.keyword)
        );
      }
      if (this.branch) {
        data = data.filter((item) => item.branch === this.branch);
      }
      return data;
    },
    branches() {
      return this.accessBranch;
    },
  },
  created() {
    if (this.$store.state.user.branch) {
      this.branch = this.$store.state.user.branch;
      return;
    }
    this.branch = this.$store.state.branch;
  },
  methods: {
    async selectRow(row) {
      this.selectedItem = row;
      this.dialog.ichiban = true;
    },
    async confirm() {
      try {
        await this.axios.post(`/dashboard/tradePoint`, {
          branch: this.branch,
          userID: this.user,
          point: this.cartPoint,
          payload: _.map(this.cart, (amount, productId) => {
            return {
              product: this.products[productId],
              amount: amount,
            };
          }),
          type: "兌換點數",
        });

        this.$toast.success("兌換成功");
        this.clearCart();
        this.load();
      } catch (error) {
        console.log(error);
        this.$toast.error("兌換失敗");
      }
    },

    async load() {
      if (!this.branch) return;
      this.cart = {};
      const { data } = await this.axios.get(`/ichiban`, {
        params: { branch: this.branch, isAvailable: true },
      });

      this.data = data;
    },
  },
  watch: {
    branch(val) {
      this.$store.commit("setState", { branch: val });
      this.load();
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .row-pointer tr :hover {
  cursor: pointer;
}
</style>
