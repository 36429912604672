<template>
  <v-dialog v-model="open" persistent width="600">
    <v-card>
      <v-card-title>{{ ichiban.key }}</v-card-title>
      <v-card-text>
        <div class="mb-3">
          <v-text-field
            label="名稱"
            outlined
            dense
            hide-details
            disabled
            :value="ichiban.name"
          ></v-text-field>
        </div>
        <div class="mb-3">
          <v-row>
            <v-col cols="4">
              <v-text-field
                label="總抽數"
                outlined
                dense
                hide-details
                disabled
                :value="ichiban.count"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                label="已抽"
                outlined
                dense
                hide-details
                disabled
                :value="ichiban.sold"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                label="已中"
                outlined
                dense
                hide-details
                disabled
                :value="ichiban.win"
              ></v-text-field>
            </v-col>
          </v-row>
        </div>

        <v-divider class="my-3"></v-divider>
        <div class="d-flex justify-center flex-wrap">
          <v-btn
            class="ma-1"
            color="primary"
            v-for="(plan, i) of ichiban.plans"
            :key="i"
            @click="setPlan(plan)"
          >
            {{ plan.count }}抽 - ${{ plan.amount }}
          </v-btn>
        </div>
        <v-row class="my-0">
          <v-col cols="4" class="pb-0">
            <v-text-field
              label="抽數"
              outlined
              dense
              hide-details
              v-model.number="plan.count"
            ></v-text-field>
          </v-col>
          <v-col cols="8" class="pb-0">
            <v-text-field
              label="金額"
              outlined
              dense
              hide-details
              v-model.number="plan.amount"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-divider class="my-3"></v-divider>
        <v-btn large block color="warning" @click="clickBtnUplod">
          <v-icon class="mr-1">mdi-camera</v-icon>
          拍照上傳
        </v-btn>
        <v-img class="mt-3" v-if="imageUrl" :src="imageUrl"></v-img>
        <input
          ref="uploader"
          class="d-none"
          type="file"
          @change="onFileChanged"
        />
        <v-divider class="my-3"></v-divider>
        <div class="d-flex justify-center flex-wrap">
          <v-item-group multiple v-model="rewards">
            <template v-for="reward of ichiban.rewards">
              <v-item v-slot="{ active, toggle }">
                <v-btn
                  :disabled="reward.hit"
                  class="ma-1"
                  :color="active ? 'success' : ''"
                  @click="toggle"
                >
                  #{{ `${reward.number}-${reward.reward}` }}
                </v-btn>
              </v-item>
            </template>
          </v-item-group>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn text class="bt-text-large" color="error" @click="open = false">
          取消
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          text
          class="bt-text-large"
          color="primary"
          :disabled="!imageUrl"
          @click="confirm"
        >
          確定
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { readAndCompressImage } from "browser-image-resizer";
const resizeConfig = {
  quality: 0.5,
  maxWidth: 800,
  maxHeight: 600,
  mimeType: "image/png",
};

export default {
  name: "DialogTradeIchiban",
  props: ["value", "item"],
  data: () => ({
    ichiban: {},
    plan: {
      count: null,
      amount: null,
    },
    rewards: [],
    imageUrl: null,
  }),
  created() {
    this.ichiban = _.cloneDeep(this.item);
  },
  computed: {
    open: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    async onFileChanged(e) {
      let file = e.target.files[0];
      try {
        let resizedImage = await readAndCompressImage(file, resizeConfig);
        let formData = new FormData();
        formData.append("file", resizedImage, Date.now());
        let { data } = await this.axios.post(`/productImage`, formData);
        this.imageUrl = data.imageUrl;
      } catch (error) {
        console.log(error);
      }
    },
    clickBtnUplod() {
      this.$refs.uploader.click();
    },
    setPlan(plan) {
      this.plan = plan;
    },
    async confirm() {
      if (!this.plan.count || (!this.plan.amount && this.plan.amount !== 0)) {
        this.$toast.error("請檢查抽數、金額欄位！");
        return;
      }
      if (!this.imageUrl) {
        this.$toast.error("請拍照上傳！");
        return;
      }
      try {
        await this.axios.post(`/ichibanRecord`, {
          ichibanId: this.ichiban._id,
          plan: this.plan,
          rewards: this.rewards,
          imageUrl: this.imageUrl,
        });
        this.$toast.success("購買成功！");
        this.$emit("load");
        this.open = false;
      } catch (error) {
        this.$toast.error("購買失敗！");
      }
    },
  },
};
</script>

<style></style>
